<template>
  <div class="js-common-wrap js-standard-operation">
    <div class="js-common-head">
      <label>历史记录</label>
      <div>
        <el-button
          type="primary"
          size="small"
          @click="search"
          v-if="getTempraryShow"
          >高级搜索</el-button
        >
        <el-button type="primary" size="small" @click="add">新增</el-button>
      </div>
    </div>
    <div class="js-common-content">
      <el-table
        class="js-table"
        :data="tableData"
        height="100%"
        empty-text="暂无数据"
        @row-click="handleRowClick"
      >
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="code" label="作业码"></el-table-column>
        <el-table-column prop="createdName" label="创建人"></el-table-column>
        <el-table-column
          prop="gmtCreated"
          label="创建时间"
          width="160"
        ></el-table-column>
        <el-table-column prop="exeCount" label="执行次数"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status"
              type="primary"
              size="small"
              @click.stop="changeStatus(scope.row)"
              >启用</el-button
            >
            <el-button
              v-else
              type="danger"
              size="small"
              @click.stop="changeStatus(scope.row)"
              >停用</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template
            slot-scope="scope"
            v-if="!scope.row.exeCount && !scope.row.status"
          >
            <i class="el-icon-edit-outline" @click.stop="edit(scope.row)"></i>
            <i class="el-icon-delete" @click.stop="deleteItem(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <common-pagination
      v-model="pageOptions"
      @curPageChange="handleCurPageChange"
    ></common-pagination>
    <standar-search
      v-model="searchDialogVisible"
      @handleSearch="handleSearch"
    ></standar-search>
    <standar-add
      v-model="addDialogVisible"
      @handleAdd="handleAdd"
    ></standar-add>
    <standar-edit
      v-model="editDialogVisible"
      :currentItem="currentItem"
      @handleItem="handleEdit"
    ></standar-edit>
    <common-delete
      v-model="deleteDialogVisible"
      @handleDelete="handleDelete"
    ></common-delete>
  </div>
</template>
<script>
import CommonPagination from "@/components/CommonPagination";
import StandarSearch from "@/components/StandarSearch";
import StandarAdd from "@/components/StandarAdd";
import StandarEdit from "@/components/StandarEdit";
import CommonDelete from "@/components/CommonDelete";
import { mapGetters } from "vuex";

export default {
  name: "",
  components: {
    CommonPagination,
    StandarSearch,
    StandarAdd,
    StandarEdit,
    CommonDelete,
  },
  data() {
    return {
      tableData: [],
      searchDialogVisible: false,
      addDialogVisible: false,
      editDialogVisible: false,
      deleteDialogVisible: false,
      options: {
        name: "",
        createdName: "",
        remark: "",
      },
      pageOptions: {
        page: 1,
        perPage: 20,
        total: 0,
      },
      currentItem: "",
    };
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapGetters(["getTempraryShow"]),
  },
  methods: {
    getList() {
      this.$axios({
        method: "get",
        url: "api/csp/sop/v1/sop/page",
        params: {
          name: this.options.name,
          createdName: this.options.createdName,
          page: this.pageOptions.page,
          perPage: this.pageOptions.perPage,
          remark: this.options.remark,
        },
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.tableData = data.data.rows;
          this.pageOptions.total = data.data.total;
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    // 分页
    handleCurPageChange() {
      this.getList();
    },
    search() {
      this.searchDialogVisible = true;
    },
    handleSearch(params) {
      this.pageOptions.page = 1;
      this.options.name = params.name;
      this.options.createdName = params.createdName;
      this.options.remark = params.remark;
      this.getList();
      this.searchDialogVisible = false;
    },
    add() {
      this.addDialogVisible = true;
    },
    handleAdd(params) {
      this.$axios({
        method: "post",
        url: "api/csp/sop/v1/sop/",
        data: {
          data: {
            name: params.name,
            code: params.code,
            remark: params.remark,
            wholeVideo: params.wholeVideo,
          },
        },
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.addDialogVisible = false;
          this.$message({
            type: "success",
            message: "新增成功！",
            duration: 1000,
          });
          this.pageOptions.page = 1;
          this.getList();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    changeStatus(row) {
      this.$axios({
        method: "put",
        url: `api/csp/sop/v1/sop/${row.id}`,
        data: {
          data: {
            name: row.name,
            code: row.code,
            remark: row.remark,
            wholeVideo: row.wholeVideo,
            status: row.status == 0 ? 1 : 0,
          },
        },
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.editDialogVisible = false;
          this.$message({
            type: "success",
            message: "状态修改成功！",
            duration: 1000,
          });
          this.pageOptions.page = 1;
          this.getList();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    handleRowClick(row) {
      let flag = !row.exeCount && !row.status;

      this.$router.push({
        path: `/standardOperation/staOperation/details`,
        query: {
          id: row.id,
          isModify: flag,
        },
      });
    },
    edit(item) {
      this.currentItem = this.$Utils.deepCopy(item);
      this.editDialogVisible = true;
    },
    handleEdit(params) {
      this.$axios({
        method: "put",
        url: `api/csp/sop/v1/sop/${params.id}`,
        data: {
          data: {
            name: params.name,
            code: params.code,
            remark: params.remark,
            wholeVideo: params.wholeVideo,
            status: params.status,
          },
        },
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.editDialogVisible = false;
          this.$message({
            type: "success",
            message: "编辑成功！",
            duration: 1000,
          });
          this.pageOptions.page = 1;
          this.getList();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    deleteItem(item) {
      this.currentItem = item;
      this.deleteDialogVisible = true;
    },
    handleDelete() {
      this.$axios({
        method: "delete",
        url: `api/csp/sop/v1/sop/${this.currentItem.id}`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.deleteDialogVisible = false;
          this.$message({
            type: "success",
            message: "删除成功！",
            duration: 1000,
          });
          this.pageOptions.page = 1;
          this.getList();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
